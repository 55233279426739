
.pass {
  border: 2px solid black;
  box-sizing: border-box;
  flex-shrink: 0;
  font-family:'Times New Roman', Times, serif;
  font-size: 11pt;
  font-weight: normal;
  line-height: 1.35;
  padding: 0.125cm 0.25cm;
  position: relative;
  width: 9.74cm; 

  &-remove {
    position: absolute;
    left: 0.5em;
    top: 0.5em;
  }

  input {
    border: 0;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    outline: 0;
    text-align: inherit;
    width: 100%;
  }

  &-title {
    font-size: 14pt;
    font-weight: bold;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
  }

  &-subtitle {
    font-size: 14pt;
    margin-bottom: 0.5em;
    text-align: center;
    text-transform: uppercase;

    span {
      border-bottom: 1px solid black;
      display: inline-block;
      line-height: 1;
      min-width: 2em;
      max-width: 2em;
      overflow: show;
    }

    input {
      font-size: 12pt;
      text-align: left;
      width: 3.2cm;
    }
  }

  &-data {
    display: flex;
    gap: 0.25cm;
  }

  &-photo {
    flex-basis: 3cm;

    &-wrap {
      border: 1px solid #e6e6e6;
      width: 3cm;
      height: 4cm;
      overflow: hidden;
      position: relative;
      display: flex;
      align-items: center;
    }

    img {
      width: 100%;
      height: auto;
    }

    input {
      appearance: none;
      width: 100%;
      height: 100%;
      position: absolute;
      opacity: 0;
      z-index: 10;
      cursor: pointer; 
      left: 0;
      top: 0;
    }
  }

  &-info {
    flex-grow: 1;
  }

  &-name {
    text-align: center;

    &-value {
      border-bottom: 1px solid black;
      font-size: 13pt;
      font-weight: bold;
      min-height: 1.35em;
      min-width: 3em;
    }

    &-label {
      font-size: 10pt;
    }
  }

  &-signatory {
    align-items: top;
    display: flex;
    margin-top: 0.5em;

    :nth-child(1) { flex-basis: 30%; }
    :nth-child(2) { flex-basis: 30%; }
    :nth-child(3) { flex-basis: 40%; text-align: right; padding-top: 0.5em; line-height: 1.25; }

    textarea {
      font-family: inherit;
      font-size: inherit;
      width: 100%;
      height: 100%;
      border: 0;
      box-sizing: border-box;
      margin: 0;
      overflow: hidden;
      padding: 0;
      resize: none;
      text-align: inherit;      
    }
  }

  &-issued,
  &-expires {
    display: flex;
    gap: 0.25em;
    justify-content: space-between;
    line-height: 1.2;
    margin-top: 0.125em;
  }

  &-issued {
    margin-top: 0.25em;
  }

  &-date-wrap {
    overflow: hidden;
    position: relative;

    input {
      opacity: 0;
      width: 100%;
      font-size: 10px;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;

      &::-webkit-calendar-picker-indicator {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media print {
  .pass {
    break-inside: avoid;
    
    &-remove {
      display: none;
    }
  }
}  
