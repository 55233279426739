.App {
}


.passes {
  display: flex;
  flex-wrap: wrap;  
  gap: 0.52cm;
  margin: auto;
  width: 20cm;
}


.settings {
  padding: 0.5em 0.75em;
  position: fixed;
  right: 0;
  top: 0;

  @media print {
    display: none;
  }
}